@font-face {
  font-family: 'Frutiger';
  src: url('../fonts/Frutiger.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Frutiger';
  src: url('../fonts/Frutiger_bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

.c-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 80%;
  margin: auto;
  padding-bottom: 7rem;
}

.section-heading {
  font-weight: bold;
  color: #012F23;
  grid-row: 1;
  grid-column: 1 / -1;
  justify-self: center;
  align-self: center;
  text-align: center;
  margin: 1rem;
}

.main-section {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto;
  column-gap: 4rem;
  row-gap: 2rem;
  padding: 4rem 0rem;
}


/* LANDING */

.landing {
  background-image: linear-gradient(to right, #395B4D 55%, #012F23 100%);
  align-items: center;
  height: 100vh;
}

.landing .information {
  display: grid;
  align-self: center;
  grid-column: 3/10;
  justify-content: start;
  gap: 1rem;
  width: 100%;
}

.landing h1 {
  font-family: 'Frutiger', sans-serif;
  font-size: 5rem;
  color: white;
  font-weight: bolder;
  text-align: left;
}
.landing h2 {
  font-family: 'Frutiger', sans-serif;
  font-size: 2.5rem;
  color: #F3F5DF;
  font-weight: bolder;
  text-align: left;
}

.profile-image {
  grid-column: 10/11;
  display: grid;
}

.profile-image img {
  max-width: 30rem;
  min-height: 30rem;
  object-fit: cover;
  border-radius: 0.5rem;
  background-color: #F3F5DF;
  border-radius       : 50%;
  border: 0.3rem solid white;
}

@media(max-width: 1350px) {
  .landing h2 {
    font-family: 'Frutiger', sans-serif;
    font-size: 2rem;
    color: #F3F5DF;
    font-weight: bolder;
    text-align: left;
  }
}

@media(max-width: 1100px) {
  .profile-image img {
    max-width: 25rem;
    min-height: 25rem;
    object-fit: cover;
    border-radius: 0.5rem;
    background-color: #F3F5DF;
    transition: all 0.2s ease-in-out;
    border-radius       : 50%;
    border: 0.3rem solid white;
  }
}
@media (max-width: 975px) {
  .landing .information {
    grid-column: 1/13;
    justify-content: center;
    margin-top: 2rem;
  }
  .information h1, .information h2 {
    justify-self: center;
    text-align: center;
  }

  .profile-image {
    grid-column: 1/13;
    justify-self: center;
  }
  .contact-icons {
    justify-self: center;
  }
}

@media(max-width: 700px)
{
  .main-section {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    column-gap: 0rem;
    row-gap: 0rem;
    padding: 4rem 0rem;
  }
  .profile-image img {
    max-width: 20rem;
    min-height: 20rem;
  }
  .landing h1 {
    font-family: 'Frutiger', sans-serif;
    font-size: 4rem;
    color: white;
    font-weight: bolder;
    text-align: left;
  }
  .landing h2 {
    font-family: 'Frutiger', sans-serif;
    font-size: 1.5rem;
  }
}

/* LANDING */

/* Footer */
footer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 2rem;
  margin: auto;
  text-align: center;
}
footer h6{
  grid-column: 2 / 3;
  align-self: center;
}

.footer-icons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 1rem;
}

@media(max-width:700px) {
  footer{
    padding: 1rem 0rem;
  }
}


/* NAV LINKS */
nav {
  padding: '1rem';
  background-color: rgba(1, 47, 35, 0.95);
  height: 4rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0;
  font-family: 'Frutiger';
  font-weight: normal;
}

.nav-content {
  display: flex;
  align-items: center;
  gap: 50px;
  margin-right: 2rem;
  font-family: 'Frutiger';
  font-size: 1.3rem !important;
  font-weight: bolder;
}

.nav-content:hover {
  color: rgb(202, 202, 202) !important;
  transform: scale(1.01);
  transition: all 0.2s ease-in-out;
}

.navbar-collapse {
  background-color: rgba(1, 47, 35, 0.9);
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 200;
  width: 100vw;
  padding-top: 1rem;
}

.collapse.show .navbar-nav {
  background-color: var(--navy);
  text-align: center;
  margin: 0;
  padding: 0;
}

.navbar-nav .nav-item .nav-link {
  color: #F3F5DF;
  display: block;
  text-align: center;
  width: 100% !important;
}

.navbar-toggler {
  border-color: white !important;
  margin-right: auto;
  margin-left: 1rem;
}

.navbar-toggler-icon {
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath stroke="white" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" d="M4 7h22M4 15h22M4 23h22"/%3E%3C/svg%3E') !important;
}

/* NAV LINKS */

/* ABOUT */

.about {
  background: linear-gradient(to bottom, #FFFFFF 95%, #F7F9EB 100%);
}

.about > *:nth-child(3) {
  grid-column: 2/7;
  grid-row: 2;
  align-items: end;
}

.about > *:nth-child(4) {
  grid-column: 2/7;
  grid-row: 3;
  align-items: start;
}

.about p {
  height: auto;
  margin: 0 auto;
  text-align: start;
}

.about img {
  grid-column: 7/12;
  grid-row: 2 / span 2;
  justify-self: center;
  max-width: 100%;
  height: auto;
}

@media(max-width: 1100px) {
  .about {
    grid-template-rows: auto auto;
  }
  .about > *:nth-child(3) {
    grid-column: 2/7;
    grid-row: 2/4;
  }
  .about > *:nth-child(4) {
    grid-row: 4;
    grid-column: 2/12;
  }
}

@media(max-width: 950px) {
  .about {
    grid-template-rows: auto auto;
  }

  .about > *:nth-child(3) {
    grid-column: 2/12;
    grid-row: 2;
    height: auto;
  }

  .about img {
    grid-column: 2/12;
    grid-row: 3;
    justify-self: center;
    max-width: 50%;
  }

  .about > *:nth-child(4) {
    grid-column: 2/12;
    grid-row: 4;
  }
}

@media(max-width: 600px) {
  .main-section {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: auto;
    column-gap: 0rem;
    row-gap: 2rem;
    padding: 4rem 0rem;
  }
  .about p {
    text-align: justify;
  }
  .about img {
    grid-column: 2/12;
    grid-row: 3;
    justify-self: center;
    max-width: 100%;
  }
}


/* ABOUT */

/* SKILLS */

.skills {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto;
  background: linear-gradient(to bottom, #F7F9EB 95%, #FFFFFF 100%);
  color: #012F23;
  font-size: 1.5rem;
}

.skills .information {
  display: grid;
  grid-row: 2;
  gap: 1rem;
  grid-column: 2 / 6;
}

.skills .sections {
  display: grid;
  grid-row: 3;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  grid-column: 2 / 6;

}

.skill-section {
  display: grid;
  justify-self: center;
  align-items: start;
}

.alt-text {
  visibility: hidden;
  width: 100px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -50px;
  opacity: 0;
  transition: opacity 0.3s;
}

.image-container:hover .alt-text {
  visibility: visible;
  opacity: 1;
}

.image-container-wrapper {
  display: grid;
  justify-self: center;
  align-items: start;
  gap: 1rem;
}
.image-container {
  display: grid;
  justify-self: center;
}

@media(max-width: 600px) {
  .skills {
    padding: 4rem auto;
  }
}

/* SKILLS */






.alt-text::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

/* PROJECTS */

.projects {
  background: linear-gradient(to bottom, #FFFFFF 95%, #F7F9EB 100%);
  color: black;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  row-gap: 2rem;
}

.projects h1 {
  grid-column: 1 / -1;
  grid-row: 1;
  text-align: center;
}

.projects .container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-column: 1 / -1;
  grid-row: 2;
  gap: 1rem;
}

.project-card {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: subgrid;
  transition: transform 0.3s ease-in-out;
}
/* .project-card:hover {
  transform: scale(1.025);
} */

.card {
  display: grid;
  grid-template-columns:2;
  row-gap: .5rem;
  border: 0px solid;
  background-color: #F3F5DF;
  color: black;
  border-radius: 4px;
  padding: 1rem 1.5rem;
}

.card .button {
  background-color: #B4C69E;
  font-weight: bold;
  color: #012F23;
  outline: 2px solid white;
  border: none;
  border-radius: 5px;
  width: 2rem;
  height: 2rem;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.title-link-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.project-modal {
  margin: 10px;
}

@media(max-width: 700px) {
  .projects .container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-column: 1 / -1;
    grid-row: 2;
    gap: 1rem;
  }
  .card {
    text-align: justify;
  }
}

/* PROJECTS */


/* CONTACT */

.contact {
  display: grid;
  background-color: #F7F9EB;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  color: #012F23;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
}

.contact .content {
  grid-column: 4/10;
}

@media(max-width: 1200px) {
  .contact .content {
    grid-column: 3/11;
  }
}

/* .resizable {
  resize: vertical;
}

::-webkit-scrollbar {
  height: 0px;
  width: 0px;
  background: #ffc99c;
  border: 0px solid #000;
} */
@media (max-width: 850px) {


  .skills {
    display: block;
    flex: unset;
      width: 100%;
      padding: 1rem 0;
  }

  .skills-p {
    width: 85%;
    margin-bottom: 1rem;
  }

  .skillsSections {
    width: 13%;
    margin-right: 1rem;
    margin-left: 1rem;
  }
  .image-container-heading {
    font-size: 1.2rem;
  }

  .image-container img {
    width: 3rem;
  }

  .alt-text {
    font-size: 0.9rem;
  }
}
